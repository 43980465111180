@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
#root {
  height: 100%;
}

body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;


}

/* Track */
::-webkit-scrollbar-track {
  background: #23272b;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-clip: content-box;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #111214;
  border-radius: 10px;


}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}